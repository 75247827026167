import { defineStore } from 'pinia'

interface Tournament {
    name: string;
    url: string;
    sign_up_url: string;
    start_date: string;
    participants_count: number;
}

interface TournamentsAvailable {
    pending: Tournament[];
    underway: Tournament[];
    complete: Tournament[];
}

interface Participant {
    profile_url: string;
    rank: string;
    name: string;
    rating_change: string;
    rating: string;
}

// define an interface tournament that contains a url and a date
interface ApiTournamentAnswer {
    ratings: Participant[],
    tournaments: TournamentsAvailable
};


export const pingpovStore = defineStore('pingpov-store', {
    state: () => {
        return {
            tournaments: {} as TournamentsAvailable,
            ratings: [] as Participant[],
            loading_tournaments: true
        }
    },
    actions: {
        async fetchTournaments() {
            const url = "https://api.pingpov.net";
            const response= await fetch(url, {
                "method": "GET",
                "mode": "cors",
            })
            const json_data = await response.json() as ApiTournamentAnswer;

            this.ratings = json_data.ratings;
            this.tournaments = json_data.tournaments;
            // sort the pending tournament
            this.tournaments.pending.sort(
                (a, b) => Date.parse(a.start_date) - Date.parse(b.start_date)
            );
            this.loading_tournaments = false;
        }
    },
});